import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';
// import ReactPlayer from 'react-player'
// import video from '../images/video_demo.mp4';



/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 * 
 * 
 * 
 * Background Image Link https://github.com/gatsbyjs/gatsby/issues/2470
 */




const BgImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100vh;

  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > video {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;' // needed for IE9+ polyfill
  }
`




export const BackgroundImage = () => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "landing_2.png" }) {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <BgImage
      fluid={data.backgroundImage.childImageSharp.fluid}
    />}
  />
)



/*
export const BackgroundVideo = () => (
  <div className="player-wrapper d-none d-md-block">
    <ReactPlayer
      volume={0}
      muted={true}
      className="react-player"
      url={video}
      height={'100%'}
      width={'100%'}
      playing
    />

  </div>
)

 */

