// You can live edit this code below the import statements
import React, { Component } from 'react';
// import { Slide, Fade } from 'react-reveal';
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"


/* https://codebushi.com/using-gatsby-image/ */

class TeamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show1: false,
      show2: false,
      showMore: false
    };

  }


  /* Not sure if best way */

  handleHover(project, val) {
    this.setState(prevState => ({
      [project]: val
    }));
  }

  toggleShow1() {
    this.setState(prevState => ({
      showMore1: !prevState.showMore1
    }));
  }
  toggleShow2() {
    this.setState(prevState => ({
      showMore2: !prevState.showMore2
    }));
  }

  render() {
    const { screenWidth } = this.props;
    return (
      <div className="row equal justify-content-center">

        <div className="col-6 col-sm-6 col-md-3 col-lg-3 card-container">
          <div className="card h-100">
            <Img
              fluid={this.props.mark.childImageSharp.fluid} />

            <div className="card-body">
              <h5 className="card-title p-1 p-md-2">Mark Partin <br /> <span className="card-subtitle text-muted">Vice President </span></h5>

              <p className="d-none d-lg-block pl-1 pl-md-2"><b>C: </b>647-202-3340  <br /><a href="mailto:mark@milborne.com">mark@milborne.com</a></p>

              <p className="d-lg-none pl-1 pl-md-2"><b>C: </b>647-202-3340  <br /><a href="mailto:mark@milborne.com">Email</a></p>

              <p className="d-none d-lg-block card-text pl-2 pr-2">Mr. Partin has 34 years of experience in residential and resort real estate development,
              marketing and sales, with extensive experience in high density urban residential projects, urban master planned
              communities and master planned resort projects, primarily in Toronto, Vancouver and the United States. </p> <p className="d-none" >Mr. Partin has
                been involved in over 150 successful real estate development projects with a total retail value of over $10 billion.
                In addition to Milborne Land Group Mr. Partin is a senior executive with Milborne Real Estate Inc. (MREI), where his focus
                 is sourcing sites for new condominium developers, raising and deploying development capital and new business development,
                 as well as sales and marketing strategy and project launch management. In addition, In addition to land acquisition Mr. Partin
                  also works with clients regarding highest and best use strategies for new land acquisitions. Mr. Partin is a frequent speaker
                   at North American real estate development conferences, including the National Association of Home Builders, OPAL and IMN.
                   Most recently, Mr. Partin was a featured speaker at the IMN Private Equity Forum on Condos, Homebuilding and Land in Miami
                   , Florida. Mr. Partin and Mr. Milborne have worked together for 27 years.</p>

            </div>
          </div>
        </div>

        <div className="col-6 col-sm-6 col-md-3 col-lg-3 card-container charles-card">
          <div className="card h-100">
            <Img
              fluid={this.props.charles.childImageSharp.fluid} />

            <div className="card-body">
              <h5 className="card-title p-1 p-md-2">Charles Stuart<br /><span className="card-subtitle text-muted">Vice President </span></h5>
              {/* hide smaller than md */}
              <p className="d-none d-lg-block pl-1 pl-md-2"><b>C: </b>416-824-3390 <br /><a href="mailto:charles.stuart@milborne.com">charles.stuart@milborne.com</a></p>

              <p className="d-lg-none pl-1 pl-md-2"><b>C: </b>416-824-3390 <br /><a href="mailto:charles.stuart@milborne.com">Email</a></p>

              <p className="d-none d-lg-block card-text pl-2 pr-2">Charles Stuart redefines the way developers look at land by using his knowledge of current and anticipated
suite pricing, as well as sales absorption rates. Years of industry experience, together with unrivalled market
knowledge and a mastery of the art of the deal, go into land transactions that result in added value for sellers,
and well-informed purchasers who understand the potential of the land, and know how to realize it.</p>

            </div>
          </div>
        </div>
        <div className="col-6 col-sm-6 col-md-3 col-lg-3 card-container">
          <div className="card h-100">
            <Img
              fluid={this.props.hunter.childImageSharp.fluid} />

            <div className="card-body">
              <h5 className="card-title p-1 p-md-2">Hunter Milborne <br /><span className="card-subtitle text-muted">CEO </span></h5>
              {/* hide smaller than md */}
              <br className="d-none d-lg-block" />
              <br className="d-none d-lg-block" />
              <br className="d-none d-lg-block" />

              <p className="d-none d-lg-block card-text pl-2 pr-2">Hunter Milborne is recognized as the “Dean of Condos” by Canadian Business Magazine for his analytical and quantitative
              approach to the development of new condominium projects. He is President and founder of Milborne Real Estate which celebrates its 43rd year in business this year.</p>  <p className="d-none">he has firmly established himself as one
              of the dominant forces in the Canadian condominium development industry, being responsible for over 100,000 sales in over 800 developments, totaling over
              $50 Billion Dollars. Mr. Milborne has also been a principle in over 50 residential developments. This year Milborne Real Estate Inc. celebrates its 43rd
              year in business..</p>


            </div>
          </div>
        </div>




      </div>

    )
  }
}


// <div className="col-xs-6 col-sm-6 col-md-6 p-0"
// >
// {
//   screenWidth < 768
//     ? (
//       <div>
//         <Img fluid={this.props.hunter.childImageSharp.fluid} />
//         <h3 className="mobile-project-heading"></h3>
//       </div>
//     )
//     : <Fade when={this.props.teamActive}>
//       <Img fluid={this.props.hunter.childImageSharp.fluid} />
//       <h3 className="web-project-heading"></h3>
//     </Fade>
// }
// </div>

// const BgImage = styled(Img)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: -1;
//   height: 100vh; // or whatever

//   // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
//   & > img {
//     object-fit: cover !important; // or whatever
//     object-position: 0% 0% !important; // or whatever
//     font-family: 'object-fit: cover !important; object-position: 0% 0% !important;' // needed for IE9+ polyfill
//   }
// `

export const FluidImageProfile = graphql`
fragment FluidImageProfile on File {
  childImageSharp {
    fluid(maxWidth: 250, maxHeight:200, quality: 70) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;


export default props => (
  <StaticQuery
    query={graphql`
    query {
      mark: file(relativePath: { eq: "mark.jpg" }) {
        ...FluidImageProfile
      }
      hunter: file(relativePath: { eq: "hunter.jpg" }) {
        ...FluidImageProfile
      }
      charles: file(relativePath: { eq: "charles7.jpg" }) {
        ...FluidImageProfile
      }
    }
`}
    render={(props) => <TeamComponent {...props} />}
  />
)