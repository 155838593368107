// You can live edit this code below the import statements
import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"


/* https://codebushi.com/using-gatsby-image/ */

class ProjectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
      show11: false,
      show12: false,
      screenWidth: null
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  /* 
   buttonClickHandler = type => {
    const newState = {};
    newState[type] = true;
    this.setState(newState);
  }
  

onClick={this.buttonClickHandler.bind(null, "play")


  */

  /* Screen sizing helpers still won't impact dynamic resizing*/

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    console.log(this.state.screenWidth);
    this.setState({ screenWidth: window.innerWidth });
  }

  /* Not sure if best way */


  handleHover(project, val) {
    this.setState(prevState => ({
      [project]: val
    }));
  }

  render() {
    const { projects } = this.props.site.siteMetadata;
    const { screenWidth } = this.state
    return (
      <div className="row">
        <div className="col-12">



          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-6 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show1', true)} onMouseLeave={this.handleHover.bind(this, 'show1', false)} >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.summerhill.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[0].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.summerhill.childImageSharp.fluid} />
                    {this.state.show1 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[0].name}</h3>
                  </Fade>
              }
            </div>

            {/* Prince */}
            <div className="col-sm-6 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show2', true)} onMouseLeave={this.handleHover.bind(this, 'show2', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.yonge.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[1].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.yonge.childImageSharp.fluid} />
                    {this.state.show2 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[1].name}</h3>
                  </Fade>
              }
            </div>

            {/* Prince */}
            <div className="col-sm-6 d-block d-sm-none d-md-block col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show3', true)} onMouseLeave={this.handleHover.bind(this, 'show3', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.prince.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[2].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.prince.childImageSharp.fluid} />
                    {this.state.show3 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[2].name}</h3>
                  </Fade>
              }
            </div>

          </div>


          {/* Second Row */}

          <div className="row" style={{ margin: 0 }}>

            {/* Military */}
            <div className="col-sm-6 p-0 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show4', true)} onMouseLeave={this.handleHover.bind(this, 'show4', false)}>


              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.military.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[3].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.military.childImageSharp.fluid} />
                    {this.state.show4 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[3].name}</h3>
                  </Fade>
              }
            </div>

            {/* Spadina */}
            <div className="col-sm-6 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show5', true)} onMouseLeave={this.handleHover.bind(this, 'show5', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.spadina.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[5].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.spadina.childImageSharp.fluid} />
                    {this.state.show5 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[5].name}</h3>
                  </Fade>
              }
            </div>

            {/*Linelle  */}
            <div className="col-sm-6 d-block d-sm-none d-md-block col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show6', true)} onMouseLeave={this.handleHover.bind(this, 'show6', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.linelle.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[6].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.linelle.childImageSharp.fluid} />
                    {this.state.show6 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[6].name}</h3>
                  </Fade>
              }
            </div>

          </div>


          {/* Third Row */}


          <div className="row" style={{ margin: 0 }}>

            {/* Richmond*/}
            <div className="d-none d-sm-block col-sm-6 p-0 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show7', true)} onMouseLeave={this.handleHover.bind(this, 'show7', false)}
            >


              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.richmond.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[7].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.richmond.childImageSharp.fluid} />
                    {this.state.show7 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[7].name}</h3>
                  </Fade>
              }
            </div>

            {/* Church*/}
            <div className="d-none d-sm-block col-sm-6 col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show8', true)} onMouseLeave={this.handleHover.bind(this, 'show8', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.church.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[8].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.church.childImageSharp.fluid} />
                    {this.state.show8 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[8].name}</h3>
                  </Fade>
              }
            </div>

            {/*Temperance  */}
            <div className="d-none d-sm-block col-sm-6 d-block d-sm-none d-md-block col-md-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show9', true)} onMouseLeave={this.handleHover.bind(this, 'show9', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.temperance.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[9].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.temperance.childImageSharp.fluid} />
                    {this.state.show9 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[9].name}</h3>
                  </Fade>
              }
            </div>

          </div>


          <div className="row" style={{ margin: 0 }}>

            {/* Richmond*/}
            <div className="d-none d-md-block col-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show10', true)} onMouseLeave={this.handleHover.bind(this, 'show10', false)}
            >


              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.lakeshore.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[10].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.lakeshore.childImageSharp.fluid} />
                    {this.state.show10 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[10].name}</h3>
                  </Fade>
              }
            </div>

            {/* Church*/}
            <div className="d-none d-md-block col-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show11', true)} onMouseLeave={this.handleHover.bind(this, 'show11', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.avenue.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[11].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.avenue.childImageSharp.fluid} />
                    {this.state.show11 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[11].name}</h3>
                  </Fade>
              }
            </div>

            {/*Temperance  */}
            <div className="d-none d-md-block col-4 p-0 project-column" onMouseEnter={this.handleHover.bind(this, 'show12', true)} onMouseLeave={this.handleHover.bind(this, 'show12', false)}
            >
              {
                screenWidth < 768
                  ? (
                    <div>
                      <Img className="custom-image" fluid={this.props.yonge2.childImageSharp.fluid} />
                      <h3 className="mobile-project-heading">{projects[12].name}</h3>
                    </div>
                  )
                  : <Fade when={this.props.projectActive}>
                    <Img fluid={this.props.yonge2.childImageSharp.fluid} />
                    {this.state.show12 ? (
                      <div style={{ display: 'none' }}></div>
                    ) :
                      <div
                        className="overlay"
                        style={{
                          position: `absolute`,
                          height: '100%',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: `rgba(0,0,0,0.5)`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          color: `white`,
                        }}
                      >
                      </div>
                    }
                    <h3 className="web-project-heading">{projects[12].name}</h3>
                  </Fade>
              }
            </div>

          </div>




        </div>

        {/* <div className="col-12">
          MMAP
        </div> */}



      </div>


    )
  }
}

// const BgImage = styled(Img)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: -1;
//   height: 100vh; // or whatever

//   // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
//   & > img {
//     object-fit: cover !important; // or whatever
//     object-position: 0% 0% !important; // or whatever
//     font-family: 'object-fit: cover !important; object-position: 0% 0% !important;' // needed for IE9+ polyfill
//   }
// `

export const fluidImageMobile = graphql`
fragment fluidImageMobile on File {
  childImageSharp {
    fluid(maxWidth: 576, maxHeight:250) {
      ...GatsbyImageSharpFluid
    }
  }
}

`;



export default props => (
  <StaticQuery
    query={graphql`
  query {
    summerhill: file(relativePath: { eq: "summerhill.png" }) {
      ...fluidImageMobile
    }
    yonge: file(relativePath: { eq: "771-yonge.jpg" }) {
      ...fluidImageMobile
    }
    military: file(relativePath: { eq: "1625-mil.png" }) {
      ...fluidImageMobile
    }
    prince: file(relativePath: { eq: "prince.jpg" }) {
      ...fluidImageMobile
    }
    spadina: file(relativePath: { eq: "170-spadina.jpg" }) {
      ...fluidImageMobile
    }
    linelle: file(relativePath: { eq: "57-linelle.jpg" }) {
      ...fluidImageMobile
    }
    richmond: file(relativePath: { eq: "richmond.jpg" }) {
      ...fluidImageMobile
    }
    church: file(relativePath: { eq: "365-church.jpg" }) {
      ...fluidImageMobile
    }
    temperance: file(relativePath: { eq: "temperance.jpg" }) {
      ...fluidImageMobile
    }
    lakeshore: file(relativePath: { eq: "637-lakeshore.jpg" }) {
      ...fluidImageMobile
    }
    avenue: file(relativePath: { eq: "609-avenue.jpg" }) {
      ...fluidImageMobile
    }
    yonge2: file(relativePath: { eq: "5791-yonge.jpg" }) {
      ...fluidImageMobile
    }
    site {
      siteMetadata {
        projects {
          name
          link
        }
      }
    }
  }
`}
    render={(props) => <ProjectComponent {...props} />}
  />
)