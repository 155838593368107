import React, { Component } from 'react';
import CountUp, { startAnimation } from 'react-countup';
import Fade from 'react-reveal/Fade';


class Counter extends Component {

  /* Don't really need state here hamburger relies on the active state of header */

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.statsActive === true && this.props.animationPerformed < 2) {
      startAnimation(this.myCountUp1);
      startAnimation(this.myCountUp2);
      startAnimation(this.myCountUp3);
    }
    return (

      <div className="row" style={{ margin: 0 }}>
        <Fade top>
          <div className="col-xs-6 col-md-4 count-container text-center mb-4">
            <CountUp
              start={0}
              end={150}
              className="counter-component"
              duration={3}
              useEasing={false}
              useGrouping={true}
              // separator=" "
              // decimals={4}
              // decimal=","
              // prefix="EUR "
              // suffix=" <span class='counter-subheading'>Land Transactions</span>"
              // onStart={() => console.log('Started! First 💨')}
              // onComplete={onComplete}
              // onStart={onStart}
              ref={countUp => { this.myCountUp1 = countUp; }} />
            <div>
              <p className="counter-subheading">Land Transactions</p>
            </div>

          </div>

          <div className="col-xs-6 col-md-4 count-container text-center mb-4">
            <CountUp
              start={0}
              end={3}
              className="counter-component"
              duration={3}
              useEasing={false}
              useGrouping={true}
              // separator=" "
              decimals={1}
              // decimal=","
              prefix="$"
              suffix="<span class='suffix'>+ Billion</span>"
              // onStart={() => console.log('Started! Second 💨')}
              // onComplete={onComplete}
              // onStart={onStart}
              ref={countUp => { this.myCountUp2 = countUp; }} />
            <p className="counter-subheading">In Land Value</p>


          </div>
          <div className="col-xs-6 col-md-4 count-container text-center mb-4">
            <CountUp
              start={0}
              end={17}
              className="counter-component"
              duration={3}
              useEasing={false}
              useGrouping={true}
              // separator=" "
              decimals={1}
              // decimal=","
              prefix="$"
              suffix="<span class='suffix'>+ Billion</span>"
              // onStart={() => console.log('Started! Third 💨')}
              // formattingFn={(value) => { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }}
              // onComplete={onComplete}
              // onStart={onStart}
              ref={countUp => { this.myCountUp3 = countUp; }} />
            <p className="counter-subheading">In Unit Sales </p>
            {/* <p className="d-none d-lg-block">Resulting in a value of </p>
            <p className="counter-subtotal d-none d-lg-block">$5 Billion <span className="counter-subheading">Dollars</span></p> */}

          </div>
        </Fade>
      </div >

    );
  }
}

export default Counter;