import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import Waypoint from 'react-waypoint';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
// faChartLine, faBuilding, faTasks, faProjectDiagram, faArrowDown



/* Components  */
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BackgroundImage } from "../components/Background"
import { DynamicImage } from "../components/Background2"
import Counter from "../components/CounterComponent"
import ProjectComponent from "../components/ProjectComponent"
import TeamComponent from "../components/TeamComponent"


/* Images  */
import logoMobile from '../images/logo-mobile.svg';
import clientWall from '../images/client_wall_2.png';

import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import advantage1 from '../images/advantage1.png';
import advantage2 from '../images/advantage2.png';
import advantage3 from '../images/advantage3.png';




var debounce = require('lodash.debounce');


class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statsActive: false,
      projectActive: false,
      aboutActive: false,
      teamActive: false,
      processActive: false,
      contactActive: false,
      animationPerformed: 0,
      screenWidth: 0,
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false

    }
  }

  componentDidMount() {
    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', debounce(this.handleWindowSizeChange, 750))
    console.log(this.props);
  }

  componentWillMount() {
    // Don’t use this as the API is deprecated
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.handleWindowSizeChange, 750))
  }

  handleWindowSizeChange = () => {
    console.log(this.state.screenWidth);
    this.setState({ screenWidth: window.innerWidth })
  }



  handleStatsEnter = () => {
    this.setState(prevState => ({
      statsActive: !prevState.statsActive,
    }));
  };
  handleStatsLeave = () => {
    // console.log('toggled', this.state.statsActive);
    this.setState(prevState => ({
      statsActive: !prevState.statsActive,
      animationPerformed: prevState.animationPerformed + 1,
    }));
  };

  handleAboutEnter = () => {
    this.setState(prevState => ({
      aboutActive: !prevState.AboutActive,
    }));
  };
  handleAboutLeave = () => {
    // console.log('toggled', this.state.AboutActive);
    this.setState(prevState => ({
      aboutActive: !prevState.statsActive,
      animationPerformed: prevState.animationPerformed + 1,
    }));
  };

  handleProjectEnter = () => {
    this.setState(prevState => ({
      projectActive: true,
    }));
  };
  handleProjectLeave = () => {
    // console.log('toggled', this.state.projectActive);
    this.setState(prevState => ({
      projectActive: true,
    }));
  };

  handleTeamEnter = () => {
    this.setState(prevState => ({
      teamActive: true,
    }));
  };
  handleTeamLeave = () => {
    // console.log('toggled', this.state.TeamActive);
    this.setState(prevState => ({
      teamActive: true,
    }));
  };

  handleProcessLeave = () => {
    // console.log('toggled', this.state.processActive);
    this.setState(prevState => ({
      processActive: true,
    }));
  };

  handleContactLeave = () => {
    // console.log('toggled', this.state.contactActive);
    this.setState(prevState => ({
      contactActive: true,
    }));
  };


  // buttonClickHandler = type => {
  //   const newState = {};
  //   newState[type] = true;
  //   this.setState(newState);
  // }



  handleHover = (section) => {
    console.log('hovered');
    // const newState = {};
    // newState[section] = true;
    // this.setState(newState);

    this.setState(prevState => ({
      [section]: true,
    }));
    // console.log('toggled', this.state.contactActive);
    // this.setState(prevState => ({
    //   prevState[section]: true,
    // }));
  };

  handleHoverLeave = (section) => {
    console.log('hovered left');
    // console.log('toggled', this.state.contactActive);
    // this.setState(prevState => ({
    //   prevState[section]: false,
    // }));
    this.setState(prevState => ({
      [section]: false,
    }));
  };

  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />



        {/* This should be scaling */}
        <div id="Top" className="jumbotron jumbotron-fluid text-center" style={{ margin: "1px 0" }}>
          {/* Hidden MD and up */}
          <div>

            <BackgroundImage />
            <div
              className="overlay"
              style={{
                position: `absolute`,
                height: '100%',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: `rgba(0,0,0,0.5)`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                color: `white`,
              }}
            >
            </div>
          </div>

          {/* Hidden SM and down */}
          <div className="top pb-4">
            <div className="container">
              <img style={{ height: '100px', marginBottom: '25px' }} src={logoMobile} alt="Milborne Land Group Logo" />
              <h1>MILBORNE LAND GROUP</h1>

            </div>
          </div>
        </div>


        <Waypoint
          onEnter={this.handleStatsEnter}
          onEnter={this.handleStatsLeave}
        >
          <section style={{ marginTop: '50px' }}>
            <span id="Stats" style={{ position: "absolute", top: "92.5vh" }}></span>
            <div className="content counter m-5">
              <Counter statsActive={this.state.statsActive} animationPerformed={this.state.animationPerformed} />
            </div>

          </section>

        </Waypoint>



        <Waypoint
          onEnter={this.handleAboutEnter}
          onEnter={this.handleAboutLeave}
        >
          <section>
            <span id="About" style={{ position: "absolute", top: "95.5vh" }}></span>

            <div className="jumbotron jumbotron-fluid text-center" style={{ maxHeight: '300px' }}>

              {/* Increase Font */}
              <p className="text-center intro-statement" id="pitch-statement">
                By synchronizing their expertise project sales and development land sales,
                Milborne Land Group lists, sources and sells high value, residential
                development sites while helping clients enhance their bottom line through real time market
                data and premium optimization services in development strategy, design, unit mix,
                and revenue models.
                  </p>
              <DynamicImage imgStyle={{ maxHeight: '300px' }} fluid={this.props.landing.childImageSharp.fluid} />
            </div>

          </section>

        </Waypoint>



        <Waypoint
          onEnter={this.handleProcessEnter}
          onEnter={this.handleProcessLeave}
        >

          <section>
            <header className="content major pl-1 text-center">
              <h1>OUR PROCESS</h1>
              <div className="container intro-container mt-5 mb-5">
                <h3>"Achieving the highest value for your land."</h3>
              </div>
            </header>



            <span id="Process"></span>
            <div className="container mb-5 intro-container">

              <div className="row">
                {/* Font text side */}
                <div className="col-12">
                  {/* row 1 */}
                  <div className="row align-items-center" style={{ marginTop: '25px' }}>

                    <div className="col-12 col-sm-12 col-md-8">
                      <h4 className="text-center" style={{ color: 'black' }}>WE MAXIMIZE LAND VALUE</h4>
                      <p>Too often in the Toronto market property owners rezone their sites for
the wrong product leaving millions in unrealized value on the table.
Avoid this pitfall and involve Milborne Land Group early in your project’s
development. Milborne’s front
-line residential sales experience provides
the Milborne Land Team with real time market feedback and direction on
emerging trends in the ever changing GTA residential market. With this
advantage Milborne like no other land advisor can unleashes a
development sites’ potential taping into market demand creating
maximum value for our clients whether a seller or buyer.</p>

                    </div>
                    <div className="d-none d-md-block col-md-4">
                      {/* <FontAwesomeIcon icon={faCheck} size="5x" style={{ border: '5px solid #a97d51', padding: '5px' }} /> */}
                      {/* <FontAwesomeIcon icon={faSquareFull} size="5x" /> */}
                      <img className="img-responsive" style={{ maxHeight: '100%', maxWidth: '100%' }} src={image2} alt="Land Value" />

                    </div>


                  </div>



                  <div className="row align-items-center" style={{ marginTop: '25px' }}>


                    <div className="col-12 col-sm-12 col-md-8">
                      <h4 style={{ color: 'black' }}>LAND EXPERTISE AND UNMATCHED MARKET UNDERSTANDING</h4>
                      <p>With over 150 land transactions to date at over $3.0 billion in market value, the Milborne Land Group team brings an unparalleled depth of experience executing site transactions.
                      Working through zoning and site design to enhance a property's potential combined with up to date sales information to understand buyers price thresholds we are able to achieve a property's
                      highest value.</p>

                    </div>
                    <div className="d-none d-md-block col-md-4">
                      {/* <FontAwesomeIcon icon={faCheck} size="5x" style={{ border: '5px solid #a97d51', padding: '5px' }} /> */}
                      {/* <FontAwesomeIcon icon={faSquareFull} size="5x" /> */}
                    </div>
                  </div>

                  {/* row 3 */}
                  <div className="row align-items-center" style={{ marginTop: '25px' }}>

                    <div className="col-12 col-sm-12 col-md-8">
                      <h4 style={{ color: 'black' }}>FULL SERVICE MARKETING</h4>
                      <p>                      The team’s marketing process combines the experience from
previous transactions with support from planners, marketers and
administrators. Taken together we will execute the best strategy to
realize the highest return for your property. </p>

                    </div>
                    <div className="d-none d-md-block col-md-4">
                      {/* <FontAwesomeIcon icon={faCheck} size="5x" style={{ border: '5px solid #a97d51', padding: '5px' }} /> */}
                      <img className="img-responsive" style={{ maxHeight: '100%', maxWidth: '100%' }} src={image1} alt="Full Services Provided" />

                      {/* <FontAwesomeIcon icon={faSquareFull} size="5x" /> */}
                    </div>
                  </div>
                  {/* row 4 */}





                </div>


                {/* Image side */}
                {/*               <div className="col-4 offset-md-2">
                <div className="row">

                  <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                </div>
                <div className="row">

                  <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                </div>
                <div className="row">

                  <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                </div>



              </div> */}
                {/* end of image */}


                {/* <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                <FontAwesomeIcon icon={faSquareFull} size="5x" style={{ border: '5px solid black', color: 'white' }} />
                <FontAwesomeIcon icon={faCheckSquare} size="5x" />
                <FontAwesomeIcon icon={faSquare} size="5x" /> */}
                {/* <img style={{ maxHeight: '500px', maxWidth: '100%' }} src={chalk} alt="" /> */}


              </div>

              {/* End of container */}
            </div>
          </section>

        </Waypoint>





        <Waypoint
          onEnter={this.handleTeamEnter}
          onEnter={this.handleTeamLeave}
        // onLeave={this.handleWaypointEnter("statsActive")}
        >

          <section>
            <header className="content major pl-1 text-center">
              <h1>THE TEAM</h1>
            </header>
            <span id="Team"></span>

            <header className="content major pl-1 text-center">
              <div className="container intro-container mt-5 mb-5">
                <p>The Milborne Land Group Team has over 100 years of experience in the new condominium business, having been involved in over 150 land development transactions with a dollar value of over $3 billion, resulting in the development of over $17 Billion dollars worth of suites.</p>
              </div>
            </header>

            <div className="content container-fluid pl-1 pr-1">
              <TeamComponent teamActive={this.state.teamActive} width={this.state.width} />
            </div>

          </section>

        </Waypoint>




        <section>
          <header className="d-none d-md-block content major pl-1 text-center">
            <h1>MILBORNE ADVANTAGE</h1>
          </header>
          {/* <span id="Process" style={{ position: "absolute", top: "95.5vh" }}></span> */}
          <div className="container mb-5 intro-container">
            <div className="row">

              <div className="d-none d-md-block col-md-12 text-center">
                <div className="row text-center">
                  <div className="col-md-offset-2 col-md-4 col-lg-3">
                    <h4>UNLOCK LAND VALUE</h4>
                    <img className="img-responsive" style={{ maxHeight: '100%', maxWidth: '100%' }} src={advantage1} alt="Best Use" />
                    <p className="pt-1">We approach land value
from a market perspective
thoroughly analyzing
planning, suite design and
market demands to
present the best
development site possible.</p>
                  </div>
                  <div className="d-none d-lg-block col-md-1 pt-11">
                    <FontAwesomeIcon icon={faArrowRight} size="5x" style={{ color: '#a97d51', padding: '5px', background: 'none' }} />
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <h4>MAXIMIZE LAND VALUE</h4>
                    <img className="img-responsive" style={{ maxHeight: '100%', maxWidth: '100%' }} src={advantage2} alt="Identify Pricing" />
                    <p className="pt-1">By combining our land
expertise with vital
Milborne sales information
from our pre construction
business we realize the
maximum value of a land
parcel.​</p>
                  </div>
                  <div className="d-none d-lg-block col-md-1 pt-11">
                    <FontAwesomeIcon icon={faArrowRight} size="5x" style={{ color: '#a97d51', padding: '5px', background: 'none' }} />
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <h4>STRATEGIC MARKETING</h4>
                    <img className="img-responsive" style={{ maxHeight: '100%', maxWidth: '100%' }} src={advantage3} alt="Deal Strategy" />
                    <p className="pt-1">With over 150 completed
land transactions we
understand what it takes
to align buyers with our
sellers expectations. Our
marketing process
highlights a properties
full potential. ​</p>
                  </div>

                </div>
              </div>


            </div>

            {/* End of container */}
          </div>


        </section>





        <section>
          <header className="content major pl-1 text-center">
            <h1>THE COMPANY WE KEEP</h1>
          </header>
          <span id="clients"></span>


          <header className="content major pl-1 text-center">
            <div className="container intro-container mt-5 mb-5 pr-4 pl-4">
              <p>Over the last three decades, the principal of Milborne Land Group have had the privilege of working with and learning from an unparalled group of clients including:</p>
            </div>
          </header>


          <div className="content container-fluid pl-1 pr-1">

            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-10 text-center" style={{ margin: '0 auto' }}>
                <img className="client-wall-img" src={clientWall} />
              </div>
            </div>

            {/* d-none d-md-block hide md and down */}


            {/* end of list */}
          </div>
        </section>



        <Waypoint
          onEnter={this.handleProjectEnter}
          onEnter={this.handleProjectLeave}
        // onLeave={this.handleWaypointEnter("statsActive")}
        >

          <section>
            <header className="content major pl-1 text-center">
              <h1>TRANSACTIONS</h1>
            </header>


            <header className="content major pl-1 text-center">
              <div className="container intro-container mt-5 mb-5">
                <p>With an extensive network of sellers and buyers from across the GTA here are some of our transactions completed to date:</p>
                <p className="small">*Project Profile Available on Request</p>
              </div>
            </header>


            <span id="Projects"></span>
            <div className="content projects container-fluid pl-1 pr-1">
              <ProjectComponent projectActive={this.state.projectActive} />
            </div>

          </section>

        </Waypoint>







        <section id="Contact">
          <header className="content major pl-1 mb-xs-3 mb-md-6 text-center">
            <h1>CONTACT US</h1>
          </header>


          <div className="content container-fluid ">
            <div className="row justify-content-center">
              <div className="col-sm-6 offset-md-1 col-md-3 contact-details">
                <h4 className="name">Mark Partin</h4>
                <p><b>C: </b>647-202-3340</p>
                <a href="mailto:mar@milborne.com">mark@milborne.com</a>
              </div>
              <div className="col-sm-6 col-md-3 contact-details">
                <h4 className="name">Charles Stuart</h4>
                <p><b>C: </b>416-824-3390</p>
                <a href="mailto:mar@milborne.com">charles.stuart@milborne.com</a>
              </div>
              <div className="col-sm-12 col-md-3 contact-details">
                <h4 className="name">Milborne Group Office</h4>
                <p><b>T: </b><a href="tel:4169289998">416-928-9998</a></p>
                <p>385 Madison Ave, Toronto, ON - M4V 2W7</p>
              </div>
            </div>

          </div>

          {/* lEGAL */}
          <footer>


            <div className="col-12 text-center">
              <p className="text-muted small mb-1 mb-lg-0">385 Madison Ave, Toronto, ON - M4V 2W7 - Office: 647-202-3340 - Email: info@milbornelandgroup.com</p>

              <p className="text-muted small mb-1 mb-lg-0">© Copyright 2019 - All Rights Reserved - Toronto, Canada</p>

              <ul className="list-inline" >

                <li className="list-inline-item"><a href="http://milborne.com/">Privacy </a></li>

                <li className="list-inline-item"><a href="http://milborne.com/">Accessibility </a></li>

                <li className="list-inline-item"><a href="http://milborne.com/">Terms</a></li>

              </ul>

            </div>

          </footer>



        </section>








      </Layout >
    )
  }
}

// export default IndexPage



export const LandingImage = graphql`
fragment LandingImage on File {
    childImageSharp {
  fluid(maxWidth: 1200) {
    ...GatsbyImageSharpFluid
  }
  }
}
`;


export default props => (
  <StaticQuery
    query={graphql`
    query {
      landing: file(relativePath: { eq: "landing.jpg" }) {
        ...LandingImage
      }
    }
`}
    render={(props) => <IndexPage {...props} />}
  />
)




